<template>
  <div>
    <d-button
      :text="selectedModel ? 'admin.duplicate.without-model' : 'admin.duplicate.with-model'"
      class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
      @on:button-click="scrollToCategoryList"
    />

    <category-list
      v-if="selectedModel || getSelectedCategory"
    />

    <div v-if="getSelectedCategory" class="border-bottom-grey mt-4 mb-4"/>

    <category-list-duplicate
      v-show="!selectedModel || getSelectedCategory"
      id="categoryListDuplicateId"
    />
  </div>
</template>
<script>
import {getClubs} from "@api/services/club/club.api";
import CategoryList from "@views/duplicate/shop/CategoryList";
import CategoryListDuplicate from "@views/duplicate/shop/CategoryListDuplicate";

export default {
  components: {CategoryListDuplicate, CategoryList},
  data: () => ({
    duplicationModel: false,
    search: '',
    withModel: false,
    selectedModel: false,
  }),
  computed: {
    isCategoryListActivated() {
      return this.$store.getters['duplicate/getActivateCategoryList'];
    },
    getSelectedCategory() {
      return this.$store.getters['duplicate/getSelectedCategoryModel'];
    }
  },
  methods: {
    scrollToCategoryList() {
      this.selectedModel = !this.selectedModel;
      this.$nextTick(() => {
        this.$store.commit('duplicate/setSelectedCategoryModel', null);
        setTimeout(() => {
          document.getElementById('categoryListDuplicateId').scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 300)
        ;
      });
    },
  },
  mounted() {
    this.$store.commit("duplicate/setCheckedCategoryList", [])
    this.$store.commit("duplicate/setSelectedCategoryModel", null)
  }
}
</script>
